import {combineReducers} from "redux"
import {authReducer} from "./authReducer";
import {newsReducer} from "./newsReducer";
import {cexReducer} from "./cexReducer";

import {arbitrageReducer} from "./arbitrageReducer";

export default combineReducers({
    auth: authReducer,
    news: newsReducer,
    cex: cexReducer,
    arbitrage: arbitrageReducer,
})