import React, { useState } from 'react';
import Svg from "../../../layout/Svg/Svg";
import { crossIcon, eyeIcon } from "../../../../assets/svg";
import styles from "./ArbitragePageListItem.module.scss"
import { getCreatedAt } from "../../../../utils/functions/date";


const getTimeDifference = (unixDate) => {
    const today = new Date()
    const date = getCreatedAt(unixDate)
    return Math.round((today.getTime() - date.getTime()) / 1000)
}


const exchangeColors = [
    {
        ex: "defilamaEth",
        color: "#8991b3",
        name: "Ethereum",
        id: 1
    },
    {
        ex: "defilamaPolygon",
        color: "#843FE1",
        name: "Polygon",
        id: 137
    },
    {
        ex: "defilamaBsc",
        color: "#ffc728",
        colorText: "#100c08",
        name: "BSC",
        id: 56
    },
    {
        ex: "defilamaArbitrum",
        color: "#fff",
        colorText: "#5871F2",
        name: "Arbitrum",
        id: 42161
    },
    {
        ex: "jupiter",
        color: "#14F195",
        colorText: "#100c08",
        name: "Solana",
        id: 42161
    },
    {
        ex: "1inchBase",
        color: "#0155fc",
        colorText: "#ffffff",
        name: "Base",
        id: 8543
    }, {
        ex: "eth",
        color: "#8991b3",
        name: "Ethereum",
        id: 1
    },
    {
        ex: "defilamaBase",
        color: "#0155fc",
        colorText: "#ffffff",
        name: "Base",
        id: 8543
    },
    {
        ex: "stonfi",
        color: "#0098ea",
        name: "Ton",
        id: 19
    },
    {
        ex: "dedust",
        color: "#0098ea",
        name: "Ton",
        id: 20
    },

]

const exText = (ex) => {
    if (ex.startsWith('defilama') || ex.startsWith('jupiter') || ex.startsWith('1inch')|| ex.startsWith('stonfi')|| ex.startsWith('dedust')) return "SWAP"
    else if (ex.toLowerCase() === "okex") return "OKX"
    else return ex.toUpperCase()
}

function ArbitragePageListItem({
    AskCount,
    AskAmount,
    BidAmount,
    AskAmountUSDT,
    BidAmountUSDT,
    BidPrice,
    AskPrice,
    BidCount,
    Chain,
    FeeUSDT,
    Profit,
    SpotFee,
    Symbol,
    onAddToBlackList,
    onAddToHidden,
    Ex1,
    Ex2,
    isArb,
    Link1,
    Link2,
    Straight,
    Spoted,
    isCexToDex,
    isDexToDex,
    ProfitPct,
    Contract,
    ContractLink,
    ContractAsk,
    ContractAskLink,
    ContractBid,
    ContractBidLink,
    DexScreenerLink,
    HoldersLink,
    GoPlusLink,
    BuyTaxAsk,
    SellTaxAsk,
    BuyTaxBid,
    SellTaxBid,
    SellTax,
    BuyTax,
    CanChangeAsk,
    CanChangeBid,
    CanChange,
}) {


    const spotedText = getTimeDifference(Spoted)

    let exAsk = Straight ? Ex1 : Ex2
    let exBid = !Straight ? Ex1 : Ex2
    const linkAsk = Straight ? Link1 : Link2
    const linkBid = !Straight ? Link1 : Link2


    const setOrderText = (amount) => {
        const types = [
            [[1], 'ордер'],
            [[2, 3, 4], 'ордера'],
        ]
        const type = types.find(item => item[0].includes(amount))
        return type ? type[1] : 'ордеров'
    }

    const openNewBrowserWindow = (spendingTokenAddress, spendingChainId, receivingTokenAddress, receivingChainId, AskAmount) => {
        const url = `https://lifi.1whale.io/?fromChain=${spendingChainId}&toChain=${receivingChainId}&fromToken=${spendingTokenAddress}&toToken=${receivingTokenAddress}&amount=${AskAmount}`;
        window.open(url, "_blank", "width=400,height=700");
    };

    const setDateText = (spotedAt) => {
        const types = [
            [24 * 60 * 60, "more than a day ago"],
            [60 * 60, `${Math.round(spotedAt / 60 / 60)} hour ago`],
            [60, `${Math.round(spotedAt / 60)} min. ago`],
        ]

        const type = types.find(item => spotedAt >= item[0])

        return type ? type[1] : 'a few seconds ago'
    }
    // // Фильтрация undefined элементов
    // const filteredExchangeColors = exchangeColors.filter(item => item !== undefined);


    const exchangeItem = exchangeColors.find(item => {
        // console.log("Item: ", item)

        // console.log('Initial exchangeColors:', exchangeColors);
        // console.log('Filtered exchangeColors:', filteredExchangeColors);


        if (isCexToDex && (item.ex === exBid || item.ex === exAsk)) {
            // console.log('Found match:', item.ex);
            return true;
        } else {
            // console.log('Checked item:', item.ex);
            return false;
        }
    });
    const askItem = exchangeColors.find(item => isDexToDex && item.ex === exAsk);
    const bidItem = exchangeColors.find(item => isDexToDex && item.ex === exBid);


    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            // alert('Адрес Контракта скопирован в буфер обмена!');
        }).catch(err => {
            // console.error('Не удалось скопировать текст: ', err);
        });
    }

    return (
        <div className={styles["arbitrageListItem"]}>
            <div className={styles["arbitrageListItem__header"]}>
                <h5 className={styles["arbitrageListItem__title"]}>{Symbol.toUpperCase().replace("USDT", "")}</h5>
                <div className={styles["arbitrageListItem__headerBtns"]}>
                    {
                        isArb ?
                            <>
                                <button
                                    onClick={() => onAddToHidden({ Symbol, Ex1, Ex2 })}
                                    className={styles["arbitrageListItem__hiderBtn"]}>
                                    <Svg className={styles["arbitrageListItem__hideIcon"]} id={eyeIcon} />
                                </button>
                                <button
                                    className={styles["arbitrageListItem__hiderBtn"]}
                                    onClick={() => onAddToBlackList(Symbol)}
                                >
                                    <Svg className={styles["arbitrageListItem__crossIcon"]} id={crossIcon} />
                                </button>
                            </> : null
                    }

                </div>
            </div>
            <div
                className={`${styles["arbitrageListItem__resultBlock"]} ${styles['arbitrageListItem__resultBlock_output']}`}>
                <p className={styles["arbitrageListItem__resultBlockText"]}>
                    <span className={styles["arbitrageListItem__resultBlockText_green"]}>BUY: </span>
                    {AskAmount.toFixed(4)} ({AskAmountUSDT.toFixed(4)} USDT)
                </p>
                <p className={styles["arbitrageListItem__resultBlockText"]}>
                    <span className={styles["arbitrageListItem__resultBlockText_green"]}>ASK: </span>
                    {AskPrice} - {AskCount} {setOrderText(AskCount)}
                </p>
                {
                    !isDexToDex ?
                        <p className={styles["arbitrageListItem__resultBlockText"]}>
                            <span className={styles["arbitrageListItem__resultBlockText_green"]}>Сеть: </span>
                            {Chain}
                        </p> : null
                }
                <p className={styles["arbitrageListItem__resultBlockText"]}>
                    <span className={styles["arbitrageListItem__resultBlockText_green"]}>Комиссия Перевода: </span>
                    {FeeUSDT.toFixed(4)}$
                </p>

                <p className={styles["arbitrageListItem__resultBlockText"]}>
                    <span className={styles["arbitrageListItem__resultBlockText_green"]}>Купить: </span>
                    <a
                        className={styles["arbitrageListItem__resultBlockLinkText"]}
                        target={"_blank"}
                        rel="noreferrer"
                        href={linkAsk}>{exText(exAsk)}</a>
                </p>
                {
                    !isCexToDex && isDexToDex ?
                        <p className={styles["arbitrageListItem__resultBlockText"]}>
                            <span
                                className={styles["arbitrageListItem__resultBlockText_green"]}
                                onClick={() => copyToClipboard(ContractAsk)}
                                style={{ cursor: 'pointer' }}
                            >
                                Адрес Контракта:
                            </span>
                            {/* <span className={styles["arbitrageListItem__resultBlockText_green"]}>Адрес Контракта: </span> */}
                            <a
                                className={styles["arbitrageListItem__resultBlockLinkText"]}
                                target={"_blank"}
                                rel="noreferrer"
                                href={ContractAskLink}>{ContractAsk}</a>
                        </p> : null
                }
                {
                    !isCexToDex && isDexToDex ?
                        <div
                            className={styles["arbitrageListItem__exhcangeBlock"]}
                            style={{
                                backgroundColor: askItem.color
                            }}
                        >
                            <h6
                                className={styles["arbitrageListItem__exhcangeBlockText"]}
                                style={{
                                    color: askItem.colorText || "#fff"
                                }}
                            >{askItem.name}</h6>
                        </div> : null
                }
            </div>
            <div
                className={`${styles["arbitrageListItem__resultBlock"]} ${styles['arbitrageListItem__resultBlock_input']}`}>
                <p className={styles["arbitrageListItem__resultBlockText"]}>
                    <span className={styles["arbitrageListItem__resultBlockText_red"]}>SELL: </span>
                    {BidAmount.toFixed(4)} ({BidAmountUSDT.toFixed(4)} USDT)
                </p>
                <p className={styles["arbitrageListItem__resultBlockText"]}>
                    <span className={styles["arbitrageListItem__resultBlockText_red"]}>BID: </span>
                    {BidPrice} - {BidCount} {setOrderText(BidCount)}
                </p>
                {
                    !isDexToDex ?
                        <p className={styles["arbitrageListItem__resultBlockText"]}>
                            <span className={styles["arbitrageListItem__resultBlockText_red"]}>Сеть: </span>
                            {Chain}
                        </p> : null
                }
                <p className={styles["arbitrageListItem__resultBlockText"]}>
                    <span className={styles["arbitrageListItem__resultBlockText_red"]}>Продать: </span>
                    <a
                        className={styles["arbitrageListItem__resultBlockLinkText"]}
                        target={"_blank"}
                        rel="noreferrer"
                        href={linkBid}>{exText(exBid)}</a>
                </p>

                {
                    isCexToDex ?
                        <p className={styles["arbitrageListItem__resultBlockText"]}>
                            <span
                                className={styles["arbitrageListItem__resultBlockText_red"]}
                                onClick={() => copyToClipboard(Contract)}
                                style={{ cursor: 'pointer' }}
                            >
                                Адрес Контракта:
                            </span>

                            <a
                                className={styles["arbitrageListItem__resultBlockLinkText"]}
                                target={"_blank"}
                                rel="noreferrer"
                                href={ContractLink}>{Contract}</a>
                        </p> : null


                }
                {
                    !isCexToDex && isDexToDex ?
                        <p className={styles["arbitrageListItem__resultBlockText"]}>
                            <span
                                className={styles["arbitrageListItem__resultBlockText_red"]}
                                onClick={() => copyToClipboard(ContractBid)}
                                style={{ cursor: 'pointer' }}
                            >
                                Адрес Контракта:
                            </span>
                            {/* <span className={styles["arbitrageListItem__resultBlockText_red"]}>Адрес Контракта: </span> */}
                            <a
                                className={styles["arbitrageListItem__resultBlockLinkText"]}
                                target={"_blank"}
                                rel="noreferrer"
                                href={ContractBidLink}>{ContractBid}</a>
                        </p> : null
                }
                {
                    !isCexToDex && isDexToDex ?
                        <div
                            className={styles["arbitrageListItem__exhcangeBlock"]}
                            style={{
                                backgroundColor: bidItem.color
                            }}
                        >
                            <h6
                                className={styles["arbitrageListItem__exhcangeBlockText"]}
                                style={{
                                    color: bidItem.colorText || "#fff"
                                }}
                            >{bidItem.name}</h6>
                        </div> : null
                }

                {
                    isCexToDex && !isDexToDex ?
                        <div
                            className={styles["arbitrageListItem__exhcangeBlock"]}
                            style={{
                                backgroundColor: exchangeItem.color
                            }}
                        >
                            <h6
                                className={styles["arbitrageListItem__exhcangeBlockText"]}
                                style={{
                                    color: exchangeItem.colorText || "#fff"
                                }}
                            >{exchangeItem.name}</h6>
                        </div> : null
                }


            </div>
            {isCexToDex && !isDexToDex ? (
                <p
                    className={`${styles["arbitrageListItem__infoText"]} `}
                >
                    SellTax: <el
                        className={`
                            ${SellTax === "0"
                                ? styles["arbitrageListItem__resultBlockText_green"]
                                : SellTax != "0"
                                    ? styles["arbitrageListItem__resultBlockText_red"]
                                    : ""}`}>{SellTax}% </el>
                    | BuyTax: <el
                        className={`
                            ${BuyTax === "0"
                                ? styles["arbitrageListItem__resultBlockText_green"]
                                : BuyTax != "0"
                                    ? styles["arbitrageListItem__resultBlockText_red"]
                                    : ""}`}>{BuyTax}% </el>
                    | TaxMod: <el
                        className={`
                            ${CanChange === "disable"
                                ? styles["arbitrageListItem__resultBlockText_green"]
                                : CanChange === "enable"
                                    ? styles["arbitrageListItem__resultBlockText_red"]
                                    : ""}`}>{CanChange}</el>
                </p>
            ) : null}

            <p className={styles["arbitrageListItem__profitText"]}>
                Общая комиссия:
                <span className={styles["arbitrageListItem__profitText_blue"]}> {SpotFee.toFixed(4)}$</span>
            </p>
            <p className={styles["arbitrageListItem__profitText"]}>
                Profit:
                <span
                    className={styles["arbitrageListItem__profitText_blue"]}> {Profit.toFixed(4)}$ ({ProfitPct.toFixed(4)}%)</span>
            </p>
            {!isCexToDex && isDexToDex &&

                <button
                    className={styles["arbitrageListItem__exhcangeBlock"]}
                    style={{
                        backgroundColor: "#6F758E"
                    }}>
                    <h5 className={styles["arbitrageListItem__bridge"]} onClick={() => openNewBrowserWindow(ContractAsk, askItem.id, ContractBid, bidItem.id, AskAmount)}>Bridge</h5>

                </button>
            }
            <div className={styles["arbitrageListItem__footer"]}>
                <p className={styles["arbitrageListItem__footerText"]}>Spotted: {setDateText(spotedText)}</p>
            </div>
            <div className={styles["arbitrageListItem__footer"]}>
                {isCexToDex && !isDexToDex ?
                    <div>
                        <a className={styles["arbitrageListItem__footerText"]}

                            target={"_blank"}
                            rel="noreferrer"
                            href={HoldersLink}>Holders </a>
                        |
                        <a className={styles["arbitrageListItem__footerText"]}

                            target={"_blank"}
                            rel="noreferrer"
                            href={DexScreenerLink}> DexScreener</a>
                        |
                        <a className={styles["arbitrageListItem__footerText"]}

                            target={"_blank"}
                            rel="noreferrer"
                            href={GoPlusLink}> GoPlus</a>
                    </div> : null

                }

            </div>
        </div >
    );
}

export default ArbitragePageListItem;